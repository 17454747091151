.react-calendar {
    width: 100%;
    background: #111111;
    font-family: BeVietnamProRegular, sans-serif;
    line-height: 1.125em;
    position: relative;
    z-index: 10;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    background: #610b03;
    color: #ffffff;
    position: relative;
  }
  .react-calendar__navigation__label {
    display: block;
    width: 100%;
    height: 65px;
    position: relative;
    z-index: 2;
    font-size: 14px;
    padding: 25px 65px 0 65px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .react-calendar__navigation__label__labelText {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 20px;
    color: #eeeeee;
    font-family: BeVietnamProBold, sans-serif;
    font-size: 1rem;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    display: none;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    left: 0;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    right: 0;
  }
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    margin-top: 2px;
    margin-bottom: 20px;
    background: #222222;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    color: #ffffff;
    border-right: 2px solid #111111;
  }
  .react-calendar__month-view__weekdays__weekday:last-child{
    border: 0;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    opacity: .2;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 15px 6.6667px;
    text-align: center;
    line-height: 16px;
    position: relative;
    background: #222222;
    border-right: 2px solid #111111 !important;
    border-bottom: 2px solid #111111 !important;
    color: #ffffff;
    font-size: 0.9rem;
  }
  .react-calendar__tile:disabled {
    color: #cccccc;
    background: none;
    border: none !important;
    position: relative;
    padding-bottom: 20px;
    opacity: .2;
  }
  .react-calendar__tile:disabled::after {
    content: "U/A";
    display: block;
    width: 100%;
    font-size: .5rem;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
  .react-calendar__tile--now {
    padding-bottom: 20px;
  }
  .react-calendar__tile--now::after {
    content: "Today";
    display: block;
    width: 100%;
    font-size: .5rem;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
  .react-calendar__tile:nth-child(7n+0) {
    color: #cd5c5c;
    background: #222222;
    border-bottom: 2px solid #111111 !important;
  }
  .react-calendar__tile:disabled:nth-child(7n+0){
    color: #cccccc;
    background: none;
    border: none !important;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #ffffff !important;
    color: #111111 !important;
    opacity: 1 !important;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #111111;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  