@font-face {
	font-family: BeVietnamProBlack;
	src: url(/public/fonts/BeVietnamPro-Black.ttf);
}

@font-face {
	font-family: BeVietnamProBold;
	src: url(/public/fonts/BeVietnamPro-Bold.ttf);
}

@font-face {
	font-family: BeVietnamProRegular;
	src: url(/public/fonts/BeVietnamPro-Regular.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html, body {
	background: #000000;
	overflow: hidden;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

a, button {
	text-decoration: none;
	color: inherit;
	-webkit-tap-highlight-color: transparent;
	border: 0;
	outline: 0;
	background: transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

.scrollbar-hidden {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

input, textarea {
	border: 0;
	background: transparent;
	outline: 0;
}

.page {
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	z-index: 90;
	top: 0;
	left: 0;
	background: #000000;
}

.square::before {
	content: "";
	display: block;
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	z-index: -1;
}

@keyframes pulseDot {
	0% {
		opacity: .2;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: .2;
	}
}

.pulse-dot {
	animation: pulseDot 2s ease-in-out infinite;
}

@keyframes divLoading {
	0% {
		left: -10%;
	}
	100% {
		left: 110%;
	}
}

.div-loading {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.div-loading::after {
	content: "";
	display: block;
	width: 50px;
	height: 200%;
	background: rgba(255,255,255,.1);
	transform: rotate(20deg);
	position: absolute;
	top: -50%;
	left: 0;
	filter: blur(30px);
	animation: divLoading 1s ease-in-out infinite;
}

@keyframes backgrond-color {
	0% {
		background-color: transparent;
	}
	100% {
		background-color: transparent;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #ffffff;
	transition: background-color 5000s ease-in-out 0s;
}

@keyframes animateCheckIcon {
	0% {
		stroke-dashoffset: 800;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.animate-check-icon {
	stroke-dasharray: 800;
	animation: animateCheckIcon .4s ease-in forwards;
}